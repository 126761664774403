<template>
    <title>登录页</title>
    <div id="login-all">
        <div id="title1"><h1>城开吉牛统一认证管理系统</h1></div>
    <div class="login_frame">
        <div id="title2"><h1>登录</h1></div>
        <div class="form-group">
            <input v-model="username" type="text"  id="username"  name="username"  @keydown.enter="login" placeholder="  请输入您的账号 ">
        </div>
        <div class="form-group">
            <input  v-model="password" type="password"  id="password" name="password"  @keydown.enter="login" placeholder="  请输入您的密码 ">
        </div>
        <input type="submit" id="loginButton" @click="login" :disabled="disabled" value="立即登录" class="login1" />
    </div>
    </div>
</template>
<script>
import { ElMessageBox } from 'element-plus';
export default {
    data() {
        return {
            username: '',
            password: ''
        };
    },
    computed: {
        disabled() {
            return !(this.username && this.password);
        },
    },
    methods: {
    async login() {
        var loginData = {
            username: this.username,
            password: this.password,
            code: "vue",
            rememberMe: true
        };

        const queryParams = new URLSearchParams(loginData).toString();
        try {
            const response =  await this.$axios.post('api/VueLogin',queryParams);
             this.responseData = response.data;
             console.log(response.data);
            if (this.responseData.code === 0) {
                if (this.responseData.msg.status==0) {
                    this.authLog(this.responseData.msg.userId,this.responseData.msg.username,0,7,"登陆失败");
                    this.showLoginErrorDialog("用户被禁用");
                }else{
                    this.authLog(this.responseData.msg.userId,this.responseData.msg.username,0,6,"登陆成功");
                 // 登录成功后的操作，传递username并跳转页面
                 this.$router.push({
                    path: '/Home',
                    query: { username: this.responseData.msg.username,
                    userId:this.responseData.msg.userId }
                }); 
                }
            
            } else if (this.responseData.code === 500) {
                console.log(this.username);
                this.authLog(this.responseData.msg.userId,this.username,0,7,this.responseData.msg);
                this.showLoginErrorDialog(this.responseData.msg);
            }
            else {
                // console.error('登陆失败', responseData.msg);
                console.log("this.responseData.msg");
                 this.authLog(this.responseData.msg.userId,this.username,0,7,this.responseData.msg);
                this.showLoginErrorDialog(this.responseData.msg);
                // 登录失败后的操作，比如显示错误信息
            }
        } 
        catch (error) {
            this.showLoginErrorDialog("请求失败");
            // 请求失败的操作
        }
    },
    async authLog(userId, userName,authStatus,authSystem, authExplain) {
			try {
			const response = await this.$axios.post('api/authLog/add', {
				userId: userId,
                userName:userName,
				authStatus: authStatus,
				authExplain: authExplain,
				authSystem:authSystem
			});
			const tokenData = response.data.msg;
			}catch (error) {
				this.showLoginErrorDialog("插入日志失败");
				// 处理请求失败的情况
			}
		},
    showLoginErrorDialog(errorMessage) {
            ElMessageBox.alert(errorMessage, '登陆失败', {
                confirmButtonText: '确定'
            }).catch(() => {
        // 点击取消或关闭（规定要加上这个）
    });
        }
}
};
</script>
<style scoped>
#login-all{
    background-color: aqua;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(../IMG/background.png);
    background-repeat: no-repeat;
    background-size: cover;
}
#title1{
    margin-top: 7%;
    margin-right: 68%;
    font-size: 30px;
    text-align:center;
    color: #86ADFB;
    margin-bottom: 50pt;
}
#title2{
    text-align:left;
    margin-left: 0.4%;
    margin-top: 3%;
    margin-right: 70%;
    font-size: 30px;
    color:#32363F;
}
.login_frame{
    height: 40%;
    width: 20%;
    margin-left: 6.5%;
}
.form-group {
    margin-bottom: 20px;
    position: relative;
    margin-left: 0%;
}
input {
margin-top: 20pt;
padding-left: 20pt;
float: left;
background-color:#F4F7FD;
border:none;
width: 80%;
height: 40pt;
border-radius: 18.5pt;
outline:none;
font-size: 20px;
float: left;
}
.login1 {
    height: 50px;
    line-height: 50px;
    width: 86%;
    border: none;
    border-radius: 60px;
    background: #4280FD;
    color: #fff;
    font-size: 18px;
    letter-spacing: 2px;
    cursor: pointer;
    transition: .3s all linear;
    margin-top: 67px;
}
.centered-message-box .el-message-box__message {
    text-align: center;
}
</style>

