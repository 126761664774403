import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // 如果你有路由的话
import axios from 'axios';
import 'element-plus/dist/index.css';
import VueWechatTitle from 'vue-wechat-title'

const app = createApp(App);
app.use(VueWechatTitle)
app.use(router); // 使用路由

app.config.globalProperties.$axios = axios; // 直接使用 Vue 提供的 $axios

app.mount('#app');
