import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import HomePage from '../views/HomePage.vue'
import ProxyPage from '../views/ProxyPage.vue'; // 根据您的项目结构进行路径调整
import work from '../views/work.vue'
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: "统一认证管理系统"
    },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title; // 修改页面标题
      next();
    }
  },
  {
    path: '/Home',
    name: 'HomePage',
    component: HomePage,
    meta: {
      title: "统一认证管理系统"
    }
  },
  {
    path: '/ProxyPage',
    name: 'ProxyPage',
    component: ProxyPage,
    meta: {
      title: "统一认证管理系统"
    }
  },
  {
    path: '/work',
    name: 'work',
    component: work,
    meta: {
      title: "统一认证管理系统"
    }
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
