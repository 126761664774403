<template>
	<html>

	<head>
		<title>主页面</title>
	</head>

	<body>
		<div class="bjbj">
			<!-- <div class="head" @click="goToWorkPage">
				<a><i class="iconfont icon-changjingliuliang"></i>工作台</a>
			</div> -->
			<div class="top">
				<div class="logo" w><img src="../IMG/main/城开吉牛统一认证管理系统@2x.png"></div>
				<div class="denglu">
					<div class="denglu01">Hi</div>
					<div class="denglu02" v-if="userDataLoaded">{{ username }}</div>
					<div class="denglu03" @click="exitLogin()"><a href="#">退出</a></div>
				</div>
			</div>
			<div class="top2">长春城开吉牛发展有限公司</div>
			<div class="menunav">
				<div value="1" class="topic-item" @click="getBreedToken()">
					<div class="topic-item-img">
						<img src="../IMG/main/01.png">
						<p>养殖系统</p>
					</div>
					<div class="topic-item-bar1"></div>
					<div class="topic-item-bar2"></div>
				</div>
				<div class="topic-item" @click="getButcherToken()">
					<div class="topic-item-img">
						<img src="../IMG/main/02.png">
						<p>屠宰系统</p>
					</div>
					<div class="topic-item-bar1"></div>
					<div class="topic-item-bar2"></div>
				</div>
				<div class="topic-item" @click="getButcherToken2()">

					<div class="topic-item-img">
						<img src="../IMG/main/03.png">
						<p>饲料系统</p>
					</div>
					<div class="topic-item-bar1"></div>
					<div class="topic-item-bar2"></div>
				</div>
				<div id="erp" class="topic-item" @click="getErpToken()">

					<div class="topic-item-img">
						<img src="../IMG/main/04.png">
						<p>ERP(ncc)系统</p>
					</div>
					<div class="topic-item-bar1"></div>
					<div class="topic-item-bar2"></div>
				</div>
				<div class="topic-item" @click="getSale()">
						<div class="topic-item-img">
							<img src="../IMG/main/05.png">
							<p>销售管理系统</p>
						</div>
						<div class="topic-item-bar1"></div>
						<div class="topic-item-bar2"></div>
				</div>
			</div>
		</div>
		<div class="foot">© 长春城开吉牛发展有限公司</div>
	</body>

	</html>
</template>

<style src="../css/home/style.css"></style>
<style src="../css/font/font_ico/iconfont.css"></style>
<script>
import { ElMessageBox } from 'element-plus';
import { ElLoading } from 'element-plus';
import 'whatwg-fetch';
export default {
	name: 'Home',  // 组件名称
	data() {
		return {
			username: '',
			userDataLoaded: false, // 用于跟踪用户数据是否已加载的标志
			auth: '',
			userData: '',
		};
	},
	async created() {
		this.username = this.$route.query.username;
		const userId = this.$route.query.userId;
		await this.fetchUserData(userId); // 等待用户数据被获取
		this.userDataLoaded = true; // 一旦数据加载完成，设置标志
	},
	methods: {
		//获取用户参数
		async fetchUserData(userId) {
			try {
				const response = await this.$axios.get('api/user/getUser', {
					params: {
						userId: userId  // 将传递的 username 参数作为请求的查询参数
					}
				});
				const userData = response.data;
				// 在这里处理获取到的用户数据，例如将其存储在组件的数据属性中
				this.userData = userData.msg;
				this.auth = userData.msg.auth;
				console.log(this.userData);
			} catch (error) {
				console.error('获取用户数据失败', error);
				// 处理请求失败的情况
			}
		},
		//Erp跳转
		async getErpToken() {
			try {
				if (this.auth.includes('erp')) {
					// 创建一个Loading实例
					const loadingInstance = ElLoading.service({
						fullscreen: true, // 在整个窗口内显示Loading
						text: '加载中...', // 可选，Loading显示的文本
					});
					const response = await this.$axios.post('api/erp/getToken', {
						//usercode: "zhangchangming"
						usercode: this.userData.username,
						userId: this.userData.userId,
						userName: this.username,
					});
					const token = response.data.msg;
					//const redirect_uri = "http://103.131.171.194:8888/nccloud"; // 重定向地址
					// 修改目标 URL，直接使用绝对路径
					const targetUrl = token;
					// 向您的服务器发起跨域请求
					// 解析代理响应，例如：proxyResponse.data
					const newWindow = window.open(targetUrl, '_blank');
					if (!newWindow) {
						console.error('无法打开新窗口');
					}
					// 关闭Loading实例，无论请求成功还是失败都会执行
					loadingInstance.close();
					this.authLog(this.userData.userId,this.userData.username,0,1,"授权成功");
				} else {
					this.authLog(this.userData.userId,this.userData.username,1,1,"授权失败（无跳转权限）");
					this.showLoginErrorDialog("无跳转权限");
				}
			} catch (error) {
					this.authLog(this.userData.userId,this.userData.username,1,1,"ERP系统授权失败");
				this.showLoginErrorDialog(error);
									// 关闭Loading实例，无论请求成功还是失败都会执行
									// loadingInstance.close();
				// 处理请求失败的情况
			}
		},
		//养殖跳转
		async getBreedToken() {
			try {
				if (this.auth.includes('breed')) {
					const loadingInstance = ElLoading.service({
						fullscreen: true, // 在整个窗口内显示Loading
						text: '加载中...', // 可选，Loading显示的文本
					});
					// 创建一个Loading实例
					const response = await this.$axios.post('api/breed/getToken', {
						userId: this.userData.breedUserId,
						userId2: this.userData.userId,
						userName: this.username,
					});
					const url = response.data.msg;
					const newWindow = window.open(url, '_blank');
					if (!newWindow) {
						console.error('无法打开新窗口');
					}
					// 关闭Loading实例，无论请求成功还是失败都会执行
					loadingInstance.close();
					this.authLog(this.userData.userId,this.userData.username,0,2,"授权成功");
				}
				else {
					this.authLog(this.userData.userId,this.userData.username,1,2,"授权失败（无跳转权限）");
					this.showLoginErrorDialog("无跳转权限");
				}
			} catch (error) {
				if(error!='无跳转权限'){
				this.authLog(this.userData.userId,this.userData.username,1,2,"养殖系统授权失败");
				}
				this.showLoginErrorDialog(error);
				// 处理请求失败的情况
			} 
		},
		//屠宰跳转
		async getButcherToken() {
			try {
				if (this.auth.includes('butcher')) {
					// 创建一个Loading实例
					const loadingInstance = ElLoading.service({
						fullscreen: true, // 在整个窗口内显示Loading
						text: '加载中...', // 可选，Loading显示的文本
					});
					console.log(this.userData.userId);
					const response = await this.$axios.post('api/butcher/getToken3', {
						tenantCode: this.userData.butcherCode,
						userCode: this.userData.butcherUserId,
						userId: this.userData.userId,
						userName: this.username,
					});
					const tokenData = response.data.msg;
					const code = response.data.code;
					if(code==500){
						throw new Error(tokenData.message);
					}else if(code==0){
					// 直接在新窗口打开 http://frp.ecsoi.com:7073/
					const newWindow = window.open('http://103.131.169.80:8093/sysTokenLogin?token=' + tokenData, '_blank');
					if (!newWindow) {
						console.error('无法打开新窗口');
					}
					}
					// 关闭Loading实例，无论请求成功还是失败都会执行
					loadingInstance.close();
					this.authLog(this.userData.userId,this.userData.username,0,3,"授权成功");
				} else {
					this.authLog(this.userData.userId,this.userData.username,1,3,"授权失败（无跳转权限）");
					this.showLoginErrorDialog("无跳转权限");
				}
			} catch (error) {
				console.log(error);
				if(error!='无跳转权限'){
					this.authLog(this.userData.userId,this.userData.username,1,3,"屠宰系统授权失败");
				}
				//this.authLog(this.userData.userId,this.userData.username,1,1,"屠宰系统授权失败");
				this.showLoginErrorDialog(error);
				// 关闭Loading实例，无论请求成功还是失败都会执行
				//loadingInstance.close();
				// 处理请求失败的情况
			}
		},
		//饲料跳转
		async getButcherToken2() {
						try {
				if (this.auth.includes('feed')) {
					// 创建一个Loading实例
					const loadingInstance = ElLoading.service({
						fullscreen: true, // 在整个窗口内显示Loading
						text: '加载中...', // 可选，Loading显示的文本
					});
					console.log(this.userData.userId);
					const response = await this.$axios.post('api/feed/getToken3', {
						tenantCode: this.userData.feedCode,
						userCode: this.userData.feedUserId,
						userId: this.userData.userId,
						userName: this.username,
					});
					const tokenData = response.data.msg;
					const code = response.data.code;
					if(code==500){
						throw new Error(tokenData.message);
					}else if(code==0){
					// 直接在新窗口打开 http://frp.ecsoi.com:7073/
					const newWindow = window.open('http://103.131.169.135:8094/sysTokenLogin?token=' + tokenData, '_blank');
					if (!newWindow) {
						console.error('无法打开新窗口');
					}
					}
					// 关闭Loading实例，无论请求成功还是失败都会执行
					loadingInstance.close();
					this.authLog(this.userData.userId,this.userData.username,0,4,"授权成功");
				} else {
					this.authLog(this.userData.userId,this.userData.username,1,4,"授权失败（无跳转权限）");
					this.showLoginErrorDialog("无跳转权限");
				}
			} catch (error) {
				console.log(error);
				if(error!='无跳转权限'){
					this.authLog(this.userData.userId,this.userData.username,1,4,"饲料系统授权失败");
				}
				//this.authLog(this.userData.userId,this.userData.username,1,1,"屠宰系统授权失败");
				this.showLoginErrorDialog(error);
				// 关闭Loading实例，无论请求成功还是失败都会执行
				//loadingInstance.close();
				// 处理请求失败的情况
			}
		},
		async getSale(){
			try {
				if (this.auth.includes('sale')) {
					// 创建一个Loading实例
					const loadingInstance = ElLoading.service({
						fullscreen: true, // 在整个窗口内显示Loading
						text: '加载中...', // 可选，Loading显示的文本
					});
					console.log(this.userData.userId);
					const response = await this.$axios.get('api/sale/getToken', {
						tenantCode: this.userData.feedCode,
						userCode: this.userData.feedUserId,
						userId: this.userData.userId,
						userName: this.username,
					});
					const tokenData = response.data.msg;
					const code = response.data.code;
					if(code==500){
						throw new Error(tokenData.message);
					}
					// 关闭Loading实例，无论请求成功还是失败都会执行
					loadingInstance.close();
					this.authLog(this.userData.userId,this.userData.username,0,5,"授权成功");
					this.showsale("跳转成功请进入服务器查看");
				} else {
					this.authLog(this.userData.userId,this.userData.username,1,5,"授权失败（无跳转权限）");
					this.showLoginErrorDialog("无跳转权限");
				}
			} catch (error) {
				console.log(error);
				if(error!='无跳转权限'){
					this.authLog(this.userData.userId,this.userData.username,1,5,"销售系统授权失败");
				}
				//this.authLog(this.userData.userId,this.userData.username,1,1,"屠宰系统授权失败");
				this.showLoginErrorDialog(error);
				// 关闭Loading实例，无论请求成功还是失败都会执行
				//loadingInstance.close();
				// 处理请求失败的情况
			}
		},
		showLoginErrorDialog(errorMessage) {
			ElMessageBox.alert(errorMessage, '跳转失败', {
				confirmButtonText: '确定'
			}).catch(() => {
				// 点击取消或关闭（规定要加上这个）
			});
		},
		showsale(errorMessage) {
			ElMessageBox.alert(errorMessage, '跳转成功', {
				confirmButtonText: '确定'
			}).catch(() => {
				// 点击取消或关闭（规定要加上这个）
			});
		},
		goToWorkPage() {
			this.$router.push({
				path: '/work'
			});
		},
		async exitLogin(){
			this.authLog(this.userData.userId,this.userData.username,0,8,"退出登录成功");
		},
		async authLog(userId,userName, authStatus,authSystem, authExplain) {
			try {
				console.log(userName);
			const response = await this.$axios.post('api/authLog/add', {
				userId: userId,
				userName:userName,
				authStatus: authStatus,
				authExplain: authExplain,
				authSystem:authSystem
			});
			const tokenData = response.data.msg;
			}catch (error) {
				this.showLoginErrorDialog("插入日志失败");
				// 处理请求失败的情况
			}
		},
	}
}
</script>

