<template>
    <div>
      <!-- 这里可以放置 loading 或者其他 UI -->
      <p>Loading...</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProxyPage',
    mounted() {
      const targetUrl = new URLSearchParams(window.location.search).get('targetUrl');
  
      if (targetUrl) {
        console.log('Target URL:', targetUrl);
        // 获取当前页面的绝对路径
        const currentUrl = window.location.href;
        // 将当前页面的绝对路径作为基础，拼接上目标 URL 的相对路径
        const absoluteTargetUrl = new URL(targetUrl, currentUrl).href;
        console.log('Absolute Target URL:', absoluteTargetUrl);
        // 通过代理打开完整的目标 URL
        window.location.href = absoluteTargetUrl;
      } else {
        console.error('目标 URL 不存在');
      }
    }
  };
  </script>
  
  <style scoped>
  /* 这里可以添加样式 */
  </style>
  