<template>
    <div class="kkk">
        <div class="logo"><img src="../IMG/work/logo2.png"></div>
        <div class="part01">
            <div class="part01k">
                <div class="part01kcon">
                    <div class="part01kcon_i"><i class="iconfont icon-professional"></i></div>
                    <div class="part01kcon_s">
                        <div class="part01kcon_s01">养殖系统</div>
                        <div class="part01kcon_s02">150<b>位用户</b></div>
                        <div class="part01kcon_s03"><span class="sheng"><i class="iconfont icon-up-arrow"></i>5</span></div>
                    </div>
                </div>
            </div>
            <div class="part01k">
                <div class="part01kcon">
                    <div class="part01kcon_i"><i class="iconfont icon-professional"></i></div>
                    <div class="part01kcon_s">
                        <div class="part01kcon_s01">屠宰系统</div>
                        <div class="part01kcon_s02">57<b>位用户</b></div>
                        <div class="part01kcon_s03"><span class="jiang"><i class="iconfont icon-down-arrow"></i>7</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="part01k">
                <div class="part01kcon">
                    <div class="part01kcon_i"><i class="iconfont icon-professional"></i></div>
                    <div class="part01kcon_s">
                        <div class="part01kcon_s01">饲料系统</div>
                        <div class="part01kcon_s02">203<b>位用户</b></div>
                        <div class="part01kcon_s03"><span class="sheng"><i class="iconfont icon-up-arrow"></i>18</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="part01k">
                <div class="part01kcon">
                    <div class="part01kcon_i"><i class="iconfont icon-professional"></i></div>
                    <div class="part01kcon_s">
                        <div class="part01kcon_s01">ERP(ncc)系统</div>
                        <div class="part01kcon_s02">294<b>位用户</b></div>
                        <div class="part01kcon_s03"><span class="sheng"><i class="iconfont icon-up-arrow"></i>3</span></div>
                    </div>
                </div>
            </div>
            <div class="part01k">
                <div class="part01kcon">
                    <div class="part01kcon_i"><i class="iconfont icon-professional"></i></div>
                    <div class="part01kcon_s">
                        <div class="part01kcon_s01">销售管理系统</div>
                        <div class="part01kcon_s02">279<b>位用户</b></div>
                        <div class="part01kcon_s03"><span class="sheng"><i class="iconfont icon-up-arrow"></i>10</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="part02 part02_m">
            <div class="partkk pw01 ph01 parpo">
                <div class="biao_time">
                    <div class="layui-inline">
                        <label class="layui-form-label">选择日期</label>
                        <div class="layui-input-inline layui-input-wrap">
                            <div class="layui-input-prefix">
                                <i class="layui-icon layui-icon-date"></i>
                            </div>
                            <input type="text" name="date" id="date" lay-verify="date" placeholder="yyyy-MM-dd"
                                autocomplete="off" class="layui-input">
                        </div>
                    </div>
                </div>
                <div class="biao_top">登录次数</div>
                <div class="biao"><img src="../IMG/work//b01.jpg" style="width: 90%; height: 90%;"></div>
            </div>
            <div class="partkk pw01 ph01 parpo">
                <div class="biao_top">登录次数</div>
                <div class="biao"><img src="../IMG/work//b02.jpg" style="width: 90%; height: 90%;" ></div>
            </div>
        </div>
        <div class="part02">
            <div class="partkk pw02 ph01 parpo">
                <div class="biao_top">登录次数</div>
                <div class="biao"><img src="../IMG/work//b03.jpg" style="width: 90%; height: 90%;"></div>
            </div>
        </div>
    </div>
</template>
<style src="../css/home/style2.css" scoped></style>
<style src="../css/font/font_ico/iconfont.css" scoped></style>
<script>
layui.use(['form', 'laydate', 'util'], function () {
    var form = layui.form;
    var layer = layui.layer;
    var laydate = layui.laydate;
    var util = layui.util;
    // 日期
    laydate.render({
        elem: '#date'
    });

});
</script>
